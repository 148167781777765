<template>
    <div class="view">
        <div class="contentwrap">
            <van-row class="content" v-if="type == -1">
                <van-row class="item">尊敬的{{ visitorName }}先生/女生</van-row>
                <van-row>
                    <van-col span="24" class="item qrcode"><div ref="qrcode"></div></van-col>
                </van-row>
                <van-row class="item">请对门禁设备刷二维码进出</van-row>
                <van-row class="item">
                    <template>来访时间：{{ $common.formatDate(visitorTime, 'YYYY-MM-DD') }}</template>
                </van-row>
            </van-row>
            <van-row class="content sms" v-else>
                <van-row class="item">尊敬的{{ $route.query.visitorName }}先生/女生</van-row>
                <!-- <van-row class="item">您的开门密码是12321312</van-row> -->
                <van-row class="item">
                    <template>来访时间：{{ $common.formatDate(visitorTime, 'YYYY-MM-DD') }}</template>
                </van-row>
                <van-row class="item">有效期{{ type == 1 ? '一次' : '一天' }}</van-row>
                <van-row class="item">短信已发送成功</van-row>
            </van-row>
            <van-row class="share" v-if="type == -1">点击右上角分享给朋友</van-row>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import { Col, Row } from 'vant';
    import QRCode from 'qrcodejs2';

    export default {
        components: {
            [Col.name]: Col,
            [Row.name]: Row,
        },
        data() {
            return {
                uuid: null,
                visitorName: null,
                visitorTime: null,
                // 参数
                code: null,
                type: -1,
            };
        },
        computed: {},
        methods: {
            creatQrCode(uuid) {
                //清除之前的二维码
                this.$refs.qrcode.innerHTML = '';
                new QRCode(this.$refs.qrcode, {
                    text: uuid, // 需要转换为二维码的内容
                    width: 200,
                    height: 200,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H,
                });
            },
            getShortParams(callback) {
                let that = this;
                that.request({
                    params: { code: this.code },
                    url: '/shortUrlCode',
                    callback(code, data) {
                        if (data.code == 200) {
                            that.uuid = data.result.uuid;
                            that.visitorName = data.result.visitorName;
                            that.visitorTime = data.result.visitorTime;
                            callback();
                        }
                    },
                    error() {},
                });
            },
            ...mapActions(['request']),
        },
        created() {
            let that = this;
            if (that.$route.query.type) {
                that.type = that.$route.query.type;
            }
            that.code = that.$route.params.code;
            that.getShortParams(() => {
                if (that.type == -1) {
                    that.creatQrCode(that.uuid);
                }
            });
            // 微信内置分享
            document.addEventListener(
                'WeixinJSBridgeReady',
                () => {
                    // window.WeixinJSBridge.call('closeWindow'); // 关闭窗口
                    if (that.type > -1) {
                        window.WeixinJSBridge.call('hideOptionMenu');
                    } else {
                        window.WeixinJSBridge.call('showOptionMenu');
                        // 发送给好友
                        window.WeixinJSBridge.on('menu:share:appmessage', function (argv) {
                            console.log(argv);
                            window.WeixinJSBridge.invoke(
                                'sendAppMessage',
                                {
                                    img_url: 'http://www.xudc.com/uploads/0/13/20168916155813.jpg',
                                    img_width: '160',
                                    img_height: '160',
                                    link: location.href,
                                    title: '数据分析平台',
                                    desc: '欢迎使用',
                                },
                                function (res) {
                                    if (res.err_desc == '已确认分享') {
                                        //分享成功
                                    }
                                }
                            );
                        });

                        // 分享到朋友圈
                        window.WeixinJSBridge.on('menu:share:timeline', function (argv) {
                            console.log(argv);
                            window.WeixinJSBridge.invoke(
                                'shareTimeline',
                                {
                                    img_url: 'http://www.xudc.com/uploads/0/13/20168916155813.jpg',
                                    img_width: '160',
                                    img_height: '160',
                                    link: location.href,
                                    title: '数据分析平台',
                                    desc: '欢迎使用',
                                },
                                function (res) {
                                    if (res.err_msg == 'share_timeline:ok') {
                                        //分享成功
                                    }
                                }
                            );
                        });
                    }
                },
                false
            );
        },
        mounted() {},
    };
</script>
<style lang="less" scoped>
    @import '../../../styles/core.less';
    .view {
        background: url('../../../assets/images/passbg1.png');
        background-size: 100% 100%;
        background: #000;
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
    }
    .view .contentwrap {
        background: url('../../../assets/images/passbg2.png') top center no-repeat;
        background-size: 98% 100%;
        height: 550px;
        margin-top: -20px;
    }
    .view .contentwrap .content {
        padding-top: 200px;
        font-size: 14px;
    }
    .view .contentwrap .content .item {
        text-align: center;
        padding: 10px 0;
    }
    .view .contentwrap .content .item:last-child {
        padding: 0;
    }
    .view .contentwrap .content .item.qrcode {
        width: 100%;
    }
    .view .contentwrap .content .item.qrcode > div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .view .contentwrap .share {
        font-size: 13px;
        color: white;
        text-align: center;
        margin-top: 70px;
        font-weight: bold;
    }
    .view .contentwrap .content.sms .item {
        padding: 15px 0;
    }
</style>
